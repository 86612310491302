import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledH1 = styled.h1`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const PageRemoteAccessHeroSection = () => (
  <div style={{ display: "grid" }}>
    <StaticImage
      style={{
        gridArea: "1/1",
        height: 400,
      }}
      width={1920}
      layout="constrained"
      alt=""
      src="../../images/header/secure-remote-access-bg-1.jpg"
      placeholder='tracedSVG'
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
      }}
    >
      <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-11/12 flex flex-col items-center mx-auto'>
          <StyledH1 className='text-3xl md:text-5xl font-extrabold text-white uppercase text-center'>
            <span className='leading-tight'>Secure Remote Access</span>
          </StyledH1>
          <p className="text-xl text-white font-medium text-center mt-4">Easy and Secure Employee Remote Access to Your Business</p>

          <div className="flex flex-col md:flex-row items-center justify-between md:space-x-6">
            <Link to="/quote">
              <button className="bg-transparent hover:bg-white text-white hover:text-gray-900 border border-white font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
                Get a Quote
              </button>
            </Link>
            <a href="tel:9495430234">
              <button className="bg-red-600 hover:bg-red-700 text-white border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
                (949) 543-0234
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PageRemoteAccessHeroSection